import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type EmployerConfigState = {
  data: any; 
  isLoading: boolean;
};

const initialState: EmployerConfigState = {
  data: null,
  isLoading: false,
};

const employerConfigSlice = createSlice({
  name: "employerConfig",
  initialState,
  reducers: {
    setConfig(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
  },
});

export const { setConfig } = employerConfigSlice.actions;

export default employerConfigSlice.reducer;
