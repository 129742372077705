import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

type ConfirmDialogProps = DialogProps & {
  title: string;
  body: string;
  icon: ReactNode;
  loading?: boolean;
  onTapYes: () => void;
  onTapNo: () => void;
  errorDialog?: Boolean;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
};

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    title,
    body,
    icon,
    onTapYes,
    onTapNo,
    errorDialog,
    loading = false,
    submitButtonTitle = "Yes",
    cancelButtonTitle = "No",
    ...restProps
  } = props;
  return (
    <Dialog {...restProps} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>
      <DialogContent
        sx={{
          mt: 2,
          my: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Avatar
          color="error"
          sx={{ width: 72, height: 72, fontSize: "1.75rem" }}
        >
          <DeleteFilled />
        </Avatar> */}
        {icon}
        <DialogContentText sx={{ mt: 2 }}>{body}</DialogContentText>
      </DialogContent>
      {errorDialog ? (
        <DialogActions sx={{ p: "20px" }}>
          <Button
            onClick={onTapNo}
            color="primary"
            variant="outlined"
            fullWidth
            sx={{ mr: 1 }}
          >
            {`Cancel`}
          </Button>
          <Button
            onClick={onTapYes}
            color="primary"
            variant="contained"
            fullWidth
          >
            {`Download error file`}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions sx={{ p: "20px" }}>
          <Button
            onClick={onTapNo}
            color="primary"
            variant="outlined"
            fullWidth
            sx={{ mr: 1 }}
          >
            {cancelButtonTitle}
          </Button>
          <LoadingButton
            fullWidth
            disabled={loading}
            variant="contained"
            type="button"
            loading={loading}
            onClick={onTapYes}
          >
            {submitButtonTitle}
          </LoadingButton>
          {/* <Button
            loading={loading}
            onClick={onTapYes}
            color="primary"
            variant="contained"
            fullWidth
          >
            {`Yes`}
          </Button> */}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
